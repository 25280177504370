import React, { useEffect } from 'react'

import Header from '../../header/Header'
import Main from '../../main/MainUI'
import policyStyle from './policy.module.scss'
import FooterUI from '../../footer/FooterUI'
import { Center, Heading, Stack, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
export const Policy = () => {


  useEffect(() => {
    document.title = 'Политика конфеденциальности'
  }, [])
  return (
    <div className='page'>

        <Header social colorBg={'black'} color={'black'} relative={true}>
          <Link to={'/services'}>Услуги</Link>
          <Link to={'/events'}>События</Link>
          <Link to={'/photo'}>Фото</Link>
          <Link to={'/contacts'}>Контакты</Link>
          <Link to={'/uniform'} style={{color: '#FFCF23'}}>Заказ формы</Link>
        </Header>
        <Main className={policyStyle.main}>
            
            <Center>
                <Stack direction={['column']} spacing={5}>
                        <Heading fontFamily={'inherit'} as={'h1'}>Политика конфеденциальности</Heading>
                        <Heading fontFamily={'inherit'} as={'h2'}>1. Общие положения</Heading>
                        <Text whiteSpace={'pre-line'} align={'justify'}>
Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые ИП Громовой Д.В. (далее – Оператор).<br/><br/>
1.1  Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну<br/><br/>
1.2  Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта https://mandorlato.ru
                        </Text>

                        <Heading fontFamily={'inherit'} as={'h2'}>2. Основные понятия, используемые в Политике</Heading>
                        <Text whiteSpace={'pre-line'} align={'justify'}>
                        2.1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники;<br/><br/>
2.2. Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);<br/><br/>
2.3. Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://mandorlato.ru;<br/><br/>
2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств;<br/><br/>
2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;<br/><br/>
2.6. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;<br/><br/>
2.7. Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;<br/><br/>
2.8. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта https://mandorlato.ru;<br/><br/>
2.9. Пользователь – любой посетитель веб-сайта https://mandorlato.ru;<br/><br/>
2.10. Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;<br/><br/>
2.11. Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;<br/><br/>
2.12. Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;<br/><br/>
2.13. Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных.
                        </Text>

                        <Heading fontFamily={'inherit'} as={'h2'}>3. Оператор может обрабатывать следующие персональные данные Пользователя</Heading>
                        <Text whiteSpace={'pre-line'} align={'justify'}>

3.1. Фамилия, имя, отчество;<br/><br/>
3.2. Электронный адрес;<br/><br/>
3.3. Номера телефонов;<br/><br/>
3.4. Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).<br/><br/>
3.5. Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.
                        </Text>


                        <Heading fontFamily={'inherit'} as={'h2'}>4. Цели обработки персональных данных</Heading>
                        <Text whiteSpace={'pre-line'} align={'justify'}>
                        4.1. Цель обработки персональных данных Пользователя — информирование Пользователя посредством отправки электронных писем; заключение, исполнение и прекращение гражданско-правовых договоров; предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на веб-сайте.<br/><br/>
4.2. Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты balearica.dream@gmail.com с пометкой «Отказ от уведомлений о новых продуктах и услугах и специальных предложениях».<br/><br/>
4.2. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.
                        </Text>


                        <Heading fontFamily={'inherit'} as={'h2'}>5. Правовые основания обработки персональных данных</Heading>
                        <Text whiteSpace={'pre-line'} align={'justify'}>
                        5.1. Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на сайте https://mandorlato.ru. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.<br/><br/>
5.2. Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).
                        </Text>

                        <Heading fontFamily={'inherit'} as={'h2'}>6. Порядок сбора, хранения, передачи и других видов обработки персональных данных</Heading>
                        <Text whiteSpace={'pre-line'} align={'justify'}>
                        6.1. Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.<br/><br/>
6.2. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.<br/><br/>
6.3. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.<br/><br/>
6.4. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора balearica.dream@gmail.com с пометкой «Актуализация персональных данных».<br/><br/>
6.5. Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора balearica.dream@gmail.com с пометкой «Отзыв согласия на обработку персональных данных».
                        </Text>

                        <Heading fontFamily={'inherit'} as={'h2'}>7. Трансграничная передача персональных данных</Heading>
                        <Text whiteSpace={'pre-line'} align={'justify'}>
                        7.1. Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.<br/><br/>
7.2. Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных.
                        </Text>

                        <Heading fontFamily={'inherit'} as={'h2'}>8. Заключительные положения</Heading>
                        <Text whiteSpace={'pre-line'} align={'justify'}>
                        8.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты balearica.dream@gmail.com.<br/><br/>
8.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.<br/><br/>
8.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу https://mandorlato.ru/Политика конфиденциальности.
                        </Text>
                </Stack>
            </Center>
            
            

        

        </Main>
        <FooterUI></FooterUI>
    </div>
  )
}
