import React, { memo} from 'react'
import {Input, Button} from '@chakra-ui/react';

import postStyle from './form.module.scss'

import useCreatePost from '../../../hooks/useCreatePost';
export const CreateEvent = memo(() => {

 


 const {converterTextArea, sendPost, createTextArea, setTitle, title} = useCreatePost([{
  id: new Date().getTime(),
  text: '',
  image: '',
  fileName: '',
  heading: '',
}])
  

  return (
    <div className={postStyle.createContainer}>
        
        <div className={postStyle.menu}>
            <div>
                <Button onClick={sendPost}>Создать пост</Button>
                <Button onClick={createTextArea}>Добавить текст</Button>
            </div>
        </div>
        
        <div>
        <Input value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Название события'/>
        {converterTextArea}
        </div>
        


    </div>
  )
})
