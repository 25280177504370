import { Button, Input, Stack, Textarea, useToast } from '@chakra-ui/react';
import { useCallback, useContext, useMemo, useState } from 'react'

import { IoCodeSlashOutline } from "react-icons/io5";
import TextareaAutosize from 'react-textarea-autosize';
import { BsImageFill, BsXLg } from 'react-icons/bs';
import postStyle from '../components/UI/form/form.module.scss'

import Service from '../service/Service';
import { useNavigate } from 'react-router-dom';
import { imageSrc } from '../constans/ImageHost';
export default function useCreatePost(initialState) {
  const navigateTo = useNavigate();

  const toast = useToast();
  const [title, setTitle] = useState('');
  const [data, setData] = useState(initialState);  
  const [files, setFiles] = useState([]);

  const changeHandlerText = useCallback((e, id) => {
    setData((prev) => ([...prev.filter(el => {
        if(el.id === id){
            el.text = e.target.value
        }
        return el;
    })]))
  }, [initialState])

  const changeHeading = useCallback((e, id) => {
    setData((prev) => ([...prev.filter(el => {
        if(el.id === id){
            el.heading = e.target.value
        }
        return el;
    })]))
  }, [initialState])



  const addImage = useCallback((e, id) => {
        
        const file = e.target.files[0];
        setFiles((prev) => ([...prev, file]));
        const src = URL.createObjectURL(file);
        setData(prev => ([...prev.filter(el => {
            if(el.id === id){
                el.image = src;
                el.fileName = file.name
            }
            return el
        })]))
        console.log(files);

  }, [initialState])

  const addLink = (id) => {
    setData((prev) => ([...prev.filter(el => {
      if(el.id === id){
          el.text += '[link="ваша ссылка"|(текст ссылки)]';
        // el.text += '1';
      }
      return el;
    })]))
  }



  const deleteHandlerImage = useCallback((object) => {
    setFiles((prev) => ([...prev.filter(el => el.name !== object.fileName)]))
    setData(prev => ([...prev.filter(el => {
        if(el.id === object.id){
            el.image = '';
        }
        return el
    })]))

  }, [initialState])

  const converterTextArea = useMemo(() => {
    return data ? data.map(textarea => <div className={postStyle.block} key={textarea.id}>
        <Stack direction={'row'} alignItems={'center'} marginBottom={'5px'}>
            {textarea.id !== 0 ? <Button colorScheme={'red'} onClick={() => setData((prev) => ([...prev.filter(el => el.id !== textarea.id)]))}>Удалить текст</Button> : null}
            {
                textarea.image ? null : <div className={postStyle.fileViewBlock}>
                    <BsImageFill/>
                    <Input type='file' accept='image/*' onChange={(e) => addImage(e, textarea.id)}/>
                </div>
            }
            <Button onClick={() => addLink(textarea.id)}>Вставить ссылку</Button>
            
        </Stack>
        
        <Input variant={'filled'} marginBottom={'5px'} placeholder={'Заголовок блока'} value={textarea.heading} onChange={e => changeHeading(e, textarea.id)}/>
        <Textarea variant={'filled'} placeholder='Содержание' resize={'none'} value={textarea.text} onChange={(e) => changeHandlerText(e, textarea.id)} as={TextareaAutosize}/>
        
        {
            textarea.image ? <div className={postStyle.imagePrev}>
                <img src={textarea.image} alt={'image' + textarea.id}/>
                <Button onClick={(e) => deleteHandlerImage(textarea)}><BsXLg/></Button>
            </div> : null
            
        }
        
    </div>) : null
  }, [data])
  const createTextArea = useCallback((event) => {
    setData((prev) => ([...prev, {id: new Date().getTime(), text: '', image: '', fileName: '', heading: ''}]));
    setTimeout(() => window.scrollTo({
      top: document.body.scrollHeight
    }) , 200)
  }, [initialState])

  const sendPost = useCallback(async () => {
    try{
        if(!title){
          throw new Error('У события должно быть название');
        }

        if(!data[0].text){
          throw new Error('У события должно быть хоть какое-то содержание');
        }

        let changedContentSrcs = data;
        changedContentSrcs = changedContentSrcs.map(el => {
            if(el.image){
              let path = `${imageSrc}posts/${title}/${el.fileName}`;
              el.image = path;
            }
            el.text = el.text;  

            
            return el;
        });
        
        
        let postData = {
            title: title,
            content: JSON.stringify(changedContentSrcs),
            files: files,
            date: new Date().getTime(), 
            access_token: localStorage.getItem('access_token')
        }

        let response = await Service.createPost(postData);

        if(response.status !== 200){
          throw new Error(response.response.data?.message);
        }
        setData([
          {
              id: new Date().getTime(),
              text: '',
              image: '',
              fileName: '',
              heading: '',
          }
        ]);
        setFiles([]);
        setTitle('');
        navigateTo('/panel')
        toast({
          status: 'success',
          description: 'Пост добавлен',
          duration: 5000,
          isClosable: true
        })

    }
    catch(e){
        toast({
          status: 'error',
          description: e.message,
          duration: 5000,
          isClosable: true
        })
    }
  }, [initialState])


  const editPost = useCallback(async (post_id) => {
    try{
        if(!title){
          throw new Error('У события должно быть название');
        }

        if(!data[0].text){
          throw new Error('У события должно быть хоть какое-то содержание');
        }

        let changedContentSrcs = data;
        changedContentSrcs = changedContentSrcs.map(el => {
            if(el.image){
              let path = `${imageSrc}posts/${title}/${el.fileName}`;
              el.image = path;
            }
            el.text = el.text;  


            
            return el;
        });
        

        let postData = {
            post_id: post_id,
            title: title,
            content: JSON.stringify(changedContentSrcs),
            files: files,
            date: new Date().getTime(), 
            access_token: localStorage.getItem('access_token')
        }
        console.log(postData)
        let response = await Service.editPost(postData);

        if(response.status !== 200){
          throw new Error(response.response.data?.message);
        }
        navigateTo('/panel-post')
        toast({
          status: 'success',
          description: 'Пост изменен',
          duration: 5000,
          isClosable: true
        })

    }
    catch(e){
        toast({
          status: 'error',
          description: e.message,
          duration: 5000,
          isClosable: true
        })
    }
  }, [initialState])

  
  

  return {title,converterTextArea, setTitle, createTextArea, sendPost, setData, files, data, editPost}
}

