 export const navLinks = [
    {
      title: 'Услуги',
      link: '/services'
    },
    {
      title: 'События',
      link: '/events'
    },
    {
      title: 'Фото',
      link: '/photo'
    },
  
    {
      title: 'Контакты',
      link: '/contacts'
    },
    {
      title: 'Заказ формы', 
      link: '/uniform'
    }
  ];

