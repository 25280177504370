

import { useEffect, useMemo, useRef, useState } from 'react';
import FooterUI from '../../footer/FooterUI';
import Header from '../../header/Header';
import Main from '../../main/MainUI';
import postPageStyle from './postPage.module.scss'
import Servies from '../../../service/Service';
import { Link, useParams } from 'react-router-dom';

import {BsYoutube } from 'react-icons/bs';

import { IoLogoVk } from 'react-icons/io5';

import { Center, Divider, Modal, ModalCloseButton, ModalContent, ModalOverlay, Spinner } from '@chakra-ui/react';
import { useFetchData } from '../../../hooks/useFetchData';
import { EventPrev } from '../../main/EventPrev/EventPrev';
import { searchLinkInText } from '../../../utils/searchLink';
import { imageSrc } from '../../../constans/ImageHost';





function Post() {
    const [content, setContent] = useState(null);
    const [src, setSrc] = useState('');
    
    const params = useParams();

    const [data, loading] = useFetchData(Servies.getPostById, params.id);
    
    useEffect(() => {

        document.title = data?.post_title || 'Пост';

        let content = data?.post_content ? JSON.parse(data.post_content) : null;

        if(content){

            content.map(el => {
                // console.log(el)
               el.text = searchLinkInText(el.text)
            })


            setContent(content)
        }
        // console.log(content)
        // console.log(content);
        
    }, [data])
    const convert = useMemo(() => {
        return content ? content.map(el => {
            return <div className={postPageStyle.postBlock} key={el.id}>
                {el.heading && <h2>{el.heading}</h2>}
                <pre dangerouslySetInnerHTML={{__html: el.text}}></pre>
                {el.image && <img onClick={() => setSrc(el.image)} src={el.image}/>}
            </div>
                
            
        }) : null
  }, [content])
    

    return (<div className={postPageStyle.container}>
        <Modal isOpen={src.length > 0 && true} onClose={() => setSrc('')} isCentered>
            <ModalOverlay/>
            
            <ModalContent width={'fit-content'} maxW={'100%'}>
                <ModalCloseButton position={'fixed'} m={1} top={0} right={0} background={'black'} color={'white'} zIndex={10}/>
                <img src={src}/>
            </ModalContent>
        </Modal>
        <Header social colorBg={'black'} color={'black'} relative={true}>

            <Link to={'/services'}>Услуги</Link>
            <Link to={'/events'}>События</Link>
            <Link to={'/photo'}>Фото</Link>
            <Link to={'/contacts'}>Контакты</Link>
            <Link to={'/uniform'} style={{color: '#FFCF23'}}>Заказ формы</Link>
        </Header>
        <Main className={postPageStyle.main}>


        
        {loading ? <Center><Spinner/></Center> : 
        
        <>
            <h1 className={postPageStyle.title}>
                <span>{data ? data.post_title : 'Пост не найден'}</span> 
                <div>
                    <Link><IoLogoVk color='rgb(77, 132, 252)'/></Link>
                    <Link><BsYoutube color='red'/></Link>
                </div>
            </h1>
            {content ? convert : null}

            
        </>
        
        }
         <Divider borderColor={'grey'} margin={'10px 0'}/>
         <EventPrev filterId={params.id} wihoutTitle/>
        </Main>
        <FooterUI></FooterUI>
    </div>);
}

export default Post;