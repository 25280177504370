
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import event from './eventPrev.module.scss'

import { Navigation, Pagination } from 'swiper/modules'
import { Link } from 'react-router-dom'
import Servies from '../../../service/Service'
import { imageSrc } from '../../../constans/ImageHost'
import { useFetchData } from '../../../hooks/useFetchData'
import { Center, Spinner } from '@chakra-ui/react'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { BsChevronCompactLeft, BsChevronCompactRight  } from "react-icons/bs";
import { mapLinkDeafultText } from '../../../utils/searchLink'
export const EventPrev = memo(({filterId, wihoutTitle}) => {

  const nextRef = useRef(null);
  const prevRef = useRef(null);
  const swiperRef = useRef(null);
  const [gridMode, setGridMode] = useState(false);
  const [data, loading] = useFetchData(Servies.getEventsPreview);

  useEffect(() => {
    if(window.innerWidth < 1000){
      setGridMode(true);
    }
    window.addEventListener('resize', () => {
        if(window.innerWidth < 1000){
          setGridMode(true);
        }
        else{
          setGridMode(false);
        }
    })
  }, [])


  const dataMemo = useMemo(() => {

    if(filterId){
      return data ? data.filter(el => el.post_id !== filterId).map(el => <SwiperSlide key={el.post_id} className={event.mainSlides}>
        <img src={imageSrc + el.post_prev} alt={el.post_title} loading='lazy'/>
        <div className={event.inf}>
          <h1>{el.post_title}</h1>
          <pre>{el.post_text_prev}</pre>
          <Link target='_blank' to={'/post/'+el.post_id}>Подробнее</Link>
        </div>
      </SwiperSlide>) : null
    }

    if(gridMode){
      return data ? data.map(el => <div key={el.post_id} className={event.mainSlides}>
        <img src={imageSrc + el.post_prev} alt={el.post_title} loading='lazy'/>
        <div className={event.inf}>
          <h1>{el.post_title}</h1>
          <pre>{el.post_text_prev}</pre>
          <Link target='_blank' to={'/post/'+el.post_id}>Подробнее</Link>
        </div>
      </div>) : null
    }
    if(gridMode && filterId){
      return data ? data.filter(el => el.post_id !== filterId).map(el => <div key={el.post_id} className={event.mainSlides}>
        <img src={imageSrc + el.post_prev} alt={el.post_title} loading='lazy'/>
        <div className={event.inf}>
          <h1>{el.post_title}</h1>
          <pre>{el.post_text_prev}</pre>
          <Link target='_blank' to={'/post/'+el.post_id}>Подробнее</Link>
        </div>
      </div>) : null
    }



    return data ? data.map(el => <SwiperSlide key={el.post_id} className={event.mainSlides}>
      <img src={imageSrc + el.post_prev} alt={el.post_title} loading='lazy'/>
      
      <div className={event.inf}>
        <h1>{el.post_title}</h1>
        <pre>{mapLinkDeafultText(el.post_text_prev)}</pre>
        <Link target='_blank' to={'/post/'+el.post_id}>Подробнее</Link>
      </div>
    </SwiperSlide>) : null

  }, [data, filterId, gridMode])
  return (
    <section className={event.container}>
      {wihoutTitle ? null : <h1>События</h1>}

      {gridMode ? <div className={event.gridMode}>
          {
            data ? dataMemo : null
          }

      </div> : 
      
          
        <div className={event.content}>
        
        
        {

          loading ? <Center><Spinner/></Center> :

          <>
          {
            data ? <Swiper
            modules={[Navigation]}
            breakpoints={{
              1800:{
                slidesPerView: dataMemo?.length > 1 ? 4 : 1
              },
              1400:{
                slidesPerView: dataMemo?.length > 1 ? 3 : 1
              },
              1000:{
                slidesPerView: dataMemo?.length > 1 ? 2 : 1
              },
              320:{
                slidesPerView: 1
              }
            }}
            onBeforeInit={(swiper) => {
              swiper.navigation.nextEl = nextRef.current;
              swiper.navigation.prevEl = prevRef.current;
              swiperRef.current = swiper
            }}
            spaceBetween={5}
            updateOnWindowResize
            grabCursor
            navigation={{
              nextEl: nextRef.current,
              prevEl: prevRef.current,
              disabledClass: event.disabled
            }}
            className={event.mainSlider}
          >
            
            {
            dataMemo
            }
            <SwiperSlide className={event.slideMore}><Link to={'/events'}>Посмотреть всё</Link></SwiperSlide>
            
            <button ref={prevRef} onClick={() => swiperRef.current.slidePrev()}><BsChevronCompactLeft/></button>
            <button ref={nextRef} onClick={() => swiperRef.current.slideNext()}><BsChevronCompactRight/></button>
            
        </Swiper> : <Center>Нет событий</Center>
          }

          
          </>
        }
      
          
        </div>
      }

      

    </section>
  )
})
