import { Button, Input, useToast } from '@chakra-ui/react'
import postStyle from './form.module.scss'
import React, { useEffect } from 'react'
import useCreatePost from '../../../hooks/useCreatePost'
import Header from '../../header/Header'
import Main from '../../main/MainUI'
import Servies from '../../../service/Service'
import { useParams } from 'react-router-dom'

export default function EditEvent() {

    const toast = useToast();
    const params = useParams()
    const {converterTextArea, editPost, createTextArea, setTitle, title, setData} = useCreatePost([])
    

    useEffect(() => {
        (async() => {
            try{
                let response = await Servies.getPostById(params.id);
                
                const data = response.data;
                setTitle(data.post_title)
                if(data.post_content){
                    const content = JSON.parse(data.post_content);
                    console.log(content)
                    setData([...content])
                }
            }
            catch(e){
                toast({
                    status: 'error',
                    description: e.message
                })
            }
        })()
    }, [])
      
    return (
        <div className='page'>
            <Header relative={true}/>
            <Main>
            <div className={postStyle.createContainer}>
                <div className={postStyle.menu}>
                    <div>
                        <Button colorScheme='green' onClick={() => editPost(params.id)}>Сохранить</Button>
                        <Button onClick={createTextArea}>Добавить текст</Button>
                    </div>
                </div>
                
                <div>
                <Input value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Название события'/>
                {converterTextArea}
                </div>
                
        
        
            </div>
            </Main>
            
        </div>
        
    )
}
